@import url("https://fonts.googleapis.com/css2?family=Michroma&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* font-family: "Michroma", sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/* font-family:  "Montserrat", sans-serif; */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .common-layout {
    @apply flex text-left items-center justify-between border-b border-[#fff] bg-transparent text-[16px] lg:text-[23px] pb-3 outline-none text-[#FCFEFD] w-full;
  }
  .main-heading {
    @apply text-[26px] sm:text-3xl md:text-4xl !leading-[129%] lg:text-5xl xl:text-[52px] 2xl:text-[55px] 3xl:text-[62px] font-michroma text-center;
  }
  .section-heading {
    @apply text-[26px] text-white text-center sm:text-3xl md:text-4xl leading-normal 2xl:text-[42px] 3xl:text-[50px] font-medium;
  }
}
.montserrat {
  font-family: "Montserrat", sans-serif;
}
.michroma {
  font-family: "michroma", sans-serif;
}
