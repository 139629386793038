/* main css */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #0e1f35;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  overflow-x: hidden !important;
}
/*TODO: clean */
.michroma {
  font-family: "Michroma", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.font-mich {
  font-family: "Michroma", sans-serif;
}
.font-most {
  font-family: "Montserrat", sans-serif !important;
}

html {
  overflow-x: hidden !important;
}

.bg {
  background: #0e1f35;
}

.message::placeholder {
  color: rgba(252, 254, 253, 27%) !important;
  /* Set the placeholder text color to white */
}

.email::placeholder {
  color: #fff !important;
  /* Set the placeholder text color to white */
}

/* Hide default checkbox appearance */
.customCheckbox {
  appearance: none;
  -webkit-appearance: none;
  max-width: 24px;
  min-width: 24px;
  height: 24px;
  border: 2px solid #202f45;
  border-radius: 6px;
  background-color: #202f45;
  position: relative;
  cursor: pointer;
  outline: none;
  margin: 0;
  /* Ensures checkbox aligns correctly */
}

/* Styles for checked state */
.customCheckbox:checked {
  background-color: #202f45;
  border-color: #202f45;
}

/* Font Awesome icon for checked state */
.customCheckbox:checked::after {
  content: "\f00c";
  /* Font Awesome checkmark */
  font-family: "Font Awesome 6 Free";
  /* Font Awesome 6 Free */
  font-weight: 900;
  /* Font Awesome 6 Free */
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 0.75rem;
  transform: translate(-50%, -50%);
}

@media (max-width: 1000px) {
  .customCheckbox {
    width: 45px !important;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Additional reset to prevent a potential workaround for hiding */
input[type="number"] {
  -moz-appearance: textfield;
  /* For Firefox */
}

.gradient-text {
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  /* Text gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  /* Required for the pseudo-element */
}

.form-select:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none;
}

.form-select {
  background-image: url(./assets/arrowdown.png);
  background-size: 3%;
  color: #fff;
  background-position: right 20px center;
}

.offcanvas-header .btn-close {
  background-image: url(./assets/close.png) !important;
  background-size: contain;
  /* Ensure the image fits within the button */
  background-repeat: no-repeat;
  background-position: center;
  width: 3px;
  /* Adjust the width */
  height: 20px;
  /* Adjust the height */
  opacity: 1;
  /* Ensure the close button is fully visible */
  border: none;
  cursor: pointer;
  /* Remove any border if there is one */
}
.gradient-bg {
  background: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);
}

.gradient-btn-bg {
  background: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);
  transition: 0.5s ease all;
}

.gradient-text {
  -webkit-text-fill-color: white;
  -webkit-background-clip: text;
  color: white;
  width: max-content;
  transition: background 0.5s ease-in-out, color 0.5s ease-in-out;
}

/* Gradient Text normal */
.gradient-text-normal {
  background: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}
/* Hover effect: Gradient text */
.gradient-text:hover {
  background: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}
.box22:hover {
  box-shadow: 0 -2px 45px rgba(255, 81, 140, 0.6);
}
.box11:hover {
  box-shadow: 0 -2px 45px rgba(255, 126, 75, 0.6);
}

.box33:hover {
  box-shadow: 0 -2px 45px rgba(94, 48, 156, 0.6);
}
.box-33-333 {
  max-width: 1318px !important;
  /* height: 500px; */
  /* aspect-ratio: 4/8 !important; */
  /* aspect-ratio: 16/9 !important; */
  /* aspect-ratio: 1/0.55 !important; */
  /* background-color: #14233a !important; */
}

.dig-into-details {
  max-width: 1318px !important;
}
.dig-grid {
  gap: 40px !important;
}
.findbutton-hover:hover {
  border-image: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);
  border-image-slice: 1;
  background: transparent;
}
.dig-grid-content {
  margin-top: 40px !important;
  margin-bottom: 16px !important;
}
.dig-grid-content-btn {
  margin-top: 20px !important;
}
.grid-layer-img {
  max-width: 1200px;
}

.gradient-text-btn:hover {
  background: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@media only screen and (max-width: 1580px) {
  .box-33-333 {
    max-width: 1150px !important;
    aspect-ratio: 1/0.55 !important;
  }
  .dig-into-details {
    max-width: 1150px !important;
  }
  .dig-grid {
    gap: 20px !important;
  }
  .dig-grid-content {
    margin-top: 25px !important;
    margin-bottom: 10px !important;
  }
  .dig-grid-content-btn {
    margin-top: 15px !important;
  }
  .grid-layer-img {
    max-width: 950px;
  }
}

@media only screen and (max-width: 1024px) {
  .box-33-333 {
    max-width: 1150px !important;
    aspect-ratio: 1/0.6 !important;
  }
  .grid-layer-img {
    max-width: 100%;
  }
}

/* Fix for Safari gradient rendering */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .box-gradient {
      -webkit-mask-image: -webkit-radial-gradient(white, black);
    }
  }
}

@keyframes subtleRotateMove {
  0% {
    transform: translate(100px, 0) rotate(0deg);
  }
  25% {
    transform: translate(100px, 0) rotate(45deg);
  }
  50% {
    transform: translate(0, 0) rotate(-30deg);
  }
  70% {
    transform: translate(-150px, 0) rotate(0deg);
  }
  85% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(100px, 0) rotate(0deg);
  }
}

@keyframes subtleRotateMove2 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(100px, 0) rotate(25deg);
  }
  50% {
    transform: translate(0, 0) rotate(0deg);
  }
  70% {
    transform: translate(-100px, 0) rotate(0deg);
  }
  85% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(50px, 0) rotate(0deg);
  }
}

@media (max-width: 640px) {
  .box-gradient-the-future {
    background: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);
    border-radius: 50%;
    filter: blur(40px);
  }

  @keyframes subtleRotateMove {
    0% {
      transform: translate(50px, 0) rotate(0deg);
    }
    25% {
      transform: translate(50px, 0) rotate(35deg);
    }
    50% {
      transform: translate(0, 0) rotate(-20deg);
    }
    70% {
      transform: translate(-100px, 0) rotate(0deg);
    }
    85% {
      transform: translate(0, 0) rotate(0deg);
    }
    100% {
      transform: translate(50px, 0) rotate(0deg);
    }
  }

  @keyframes subtleRotateMove2 {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    25% {
      transform: translate(50px, 0) rotate(35deg);
    }
    50% {
      transform: translate(0, 0) rotate(-20deg);
    }
    70% {
      transform: translate(-70px, 0) rotate(0deg);
    }
    85% {
      transform: translate(0, 0) rotate(0deg);
    }
    100% {
      transform: translate(25px, 0) rotate(0deg);
    }
  }
}

.box-gradient {
  /* Standard syntax */
  background: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);

  /* Webkit (Safari/Chrome) old syntax */
  background: -webkit-linear-gradient(left, #ff7e4b 0%, #ff518c 50%, #66319b 100%);

  /* Mozilla Firefox old syntax */
  background: -moz-linear-gradient(left, #ff7e4b 0%, #ff518c 50%, #66319b 100%);

  /* Opera old syntax */
  background: -o-linear-gradient(left, #ff7e4b 0%, #ff518c 50%, #66319b 100%);

  /* Internet Explorer old syntax */
  background: -ms-linear-gradient(left, #ff7e4b 0%, #ff518c 50%, #66319b 100%);

  /* Fallback for older browsers */
  background-color: #ff518c;

  /* Border radius with prefixes */
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  /* Blur effect with prefixes */
  -webkit-filter: blur(70px);
  -moz-filter: blur(70px);
  -o-filter: blur(70px);
  -ms-filter: blur(70px);
  filter: blur(70px);

  /* Additional Safari blur support */
  -webkit-backdrop-filter: blur(70px);
  backdrop-filter: blur(70px);

  /* Prevent blur overflow */
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;

  /* Hardware acceleration for smoother gradients */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  /* Prevent color banding */
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000;
}

.box-gradient-the-future {
  background: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);
  border-radius: 50%;
  filter: blur(70px);
}

/* Box gradient */

.box-gradient-border-hover {
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.box-gradient-border-hover::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 2px;
  background: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.box-gradient-border-hover:hover::before {
  opacity: 1; /* Show border on hover */
}

/* ================ Virtuous CARD STYLE ====================  */
.virtuous-card {
  position: relative;
  transition: all 0.3s ease;
}

.virtuous-card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 2px;
  background: linear-gradient(90deg, #ff7e4b 0%, #ff518c 50%, #66319b 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.dow-gradient {
  background: linear-gradient(90.69deg, #ff7e4b 56.44%, #ff518c 77.66%, #ff5389 89.25%, #66319b 98.87%);
}
